<template>
  <div class="relative">
    <Spin v-if="loading" fix></Spin>

    <div class="breadcrumb">
      <div class="item" @click="$router.push('/conferences')">
        Conferences
      </div>
      <Icon style="margin:0 5px" type="ios-arrow-forward" />
      <div class="item" @click="$router.push(`/company/${company_id}`)">
        <div v-if="role === 'attendee'">
          Attendees: {{ company && company.company_name }}
        </div>
        <div v-if="role === 'exhibitor'">
          Exhibitors: {{ company && company.company_name }}
        </div>
      </div>
      <Icon style="margin:0 5px" type="ios-arrow-forward" />
      <div class="item active">
        Documents: Associated Products
      </div>
    </div>

    <div class="box">
      <div class="head">
        <div class="item">
          <div class="title">Document Title</div>
          <div class="text">{{ name }}</div>
        </div>
        <div class="item">
          <div class="title">Associated Products</div>
          <div class="text">
            <span v-if="!belong_to_products_count">N/A</span>
            <span v-else class="link"
              >{{ belong_to_products_count }}

              <span v-if="belong_to_products_count === 1">product</span>
              <span v-else>products</span>
            </span>
          </div>
        </div>
        <div class="item">
          <div class="title">Associated Factories</div>
          <div class="text">
            <span v-if="!belong_to_factories_count">N/A</span>
            <span v-else class="link">
              {{ belong_to_factories_count }}
              <span v-if="belong_to_factories_count === 1">factory</span>
              <span v-else>factories</span>
            </span>
          </div>
        </div>
      </div>
      <div class="body">
        <h2>Associated Products</h2>

        <Row
          v-if="belong_to_products_count"
          type="flex"
          justify="start"
          :gutter="16"
        >
          <i-col :key="item.id" v-for="item in products" span="6">
            <div class="item">
              <router-link
                :to="
                  item.company.level === 'Bronze'
                    ? `/company/${item.company_id}`
                    : `/products/${item.id}?type=documentation`
                "
              >
                <div class="img-box">
                  <img alt="" class="avatar" :src="item.images[0].url" />
                </div>
              </router-link>

              <div class="name">
                <router-link
                  :to="
                    `/search?q=${encodeURIComponent(
                      item.marketing_name
                    )}&type=product&marketing_name=${encodeURIComponent(
                      item.marketing_name
                    )}`
                  "
                >
                  <div class="product-name">
                    {{ item.marketing_name }}
                  </div></router-link
                >

                <router-link
                  :to="
                    `/search?q=${encodeURIComponent(
                      item.product_type
                    )}&type=product&product_type=${encodeURIComponent(
                      item.product_type
                    )}`
                  "
                >
                  <div class="product-type">
                    {{ item.product_type }}
                  </div></router-link
                >
              </div>

              <router-link :to="`/company/${item.company_id}`">
                <div class="user">
                  <div class="user-avatar">
                    <img alt="" class="avatar" :src="item.company.avatar.url" />
                  </div>
                  <div class="user-name">{{ item.company.company_name }}</div>
                </div>
              </router-link>

              <div class="tags">
                <div v-if="!item.tags || (item.tags && item.tags.length === 0)">
                  No tags
                </div>

                <div
                  class="tag"
                  :style="{ background: tag.color }"
                  v-for="tag in item.tags"
                  :key="tag.id"
                >
                  {{ tag.name }}
                </div>
              </div>
            </div>
          </i-col>
        </Row>
        <div class="empty" v-else>
          This document is not associated with any products
        </div>

        <br />

        <h2>Associated Factories</h2>

        <Table
          v-if="belong_to_factories_count"
          :columns="columns"
          :data="factories"
        >
          <template slot-scope="{ row: item }" slot="location">
            {{ item.city }},{{ item.state }},{{
              item.country_info && item.country_info.name
            }}
          </template>
          <template slot-scope="{ row: item }" slot="main_product_category">
            {{ item.main_product_category || "--" }}
          </template>
          <template slot-scope="{ row: item }" slot="factory_name">
            <router-link
              :to="`/factories/${item.id}/detail?fname=${item.factory_name}`"
              style="color:#f57c00"
            >
              <strong>{{ item.factory_name }}</strong>
            </router-link>
          </template>
        </Table>
        <div class="empty" v-else>
          This document is not associated with any factories
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _queryCompanyCompany, _queryDocumentAssociated } = api;

export default {
  components: {},
  created() {
    const { company_id, document_id } = this.$route.params;
    this.company_id = company_id;

    this.loading = true;

    Promise.all([
      _queryDocumentAssociated({ document_id, type: "product", limit: 999 }),
      _queryDocumentAssociated({ document_id, type: "factory", limit: 999 }),
      _queryCompanyCompany({ id: company_id })
    ])
      .then(([{ data: data1 }, { data: data2 }, { data: data3 }]) => {
        console.log(data1, data2);
        this.name = data1.name;
        sessionStorage.setItem("associatedDoc", data1.name);
        this.belong_to_factories_count = data1.belong_to_factories_count;
        this.belong_to_products_count = data1.belong_to_products_count;

        this.products = data1.belong_to_products.data;
        this.factories = data2.belong_to_fatories.data;

        this.role = data3.role;
        this.company = data3;
      })
      .finally(() => {
        this.loading = false;
      });
  },

  data() {
    return {
      loading: false,
      role: "",
      company: null,
      products: [],
      factories: [],
      name: "",
      belong_to_factories_count: 0,
      belong_to_products_count: 0,

      columns: [
        {
          title: "FACTORY NAME",
          slot: "factory_name"
        },
        {
          title: "MAIN PRODUCT CATEGORY",
          slot: "main_product_category"
        },
        {
          title: "LOCATION",
          slot: "location"
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
.breadcrumb {
  color: lighten(@primary-color, 30%);
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;

  .item {
    cursor: pointer;
    &.active {
      color: @primary-color;
      cursor: initial;
    }
  }
}

.relative {
  position: relative;
  min-height: 300px;
  background: #fff;
}

.box {
  min-height: 800px;
  .head {
    background: #eee;
    border-bottom: 1px solid #ccc;
    display: flex;
    padding: 20px 10px;
    .item {
      margin-right: 80px;
      .title {
        font-size: 16px;
        color: #333;
      }
      .text {
        font-size: 20px;
        color: #000;
        font-weight: bold;
      }
    }
  }
  .body {
    padding: 20px;
    h2 {
      color: #000;
      font-size: 1.5em;
      margin-bottom: 20px;
    }

    .empty {
      font-size: 16px;
      color: #999;
      font-weight: bold;
      background: #eee;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 100px 0;
      border-radius: 4px;
    }

    .item {
      margin-bottom: 20px;
      border: 1px solid #ccc;
      background: #fff;
      box-shadow: 2px 4px 6px rgba(51, 51, 51, 0.149019607843137);
      border-radius: 5px;
      .img-box {
        width: 100%;
        height: 0px;
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        border-bottom: 1px solid #ccc;
        background: #eee;
        .avatar {
          display: block;
          width: 100%;
        }
      }

      .name {
        padding: 10px;
        min-height: 80px;
        border-bottom: 1px solid #ccc;
        color: #666;
        .product-name {
          font-size: 16px;
          color: #333;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .product-type {
          font-size: 13px;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .user {
        padding: 10px;
        border-bottom: 1px solid #ccc;
        display: flex;
        align-items: center;
        .user-avatar {
          width: 30px;
          height: 30px;
          background: #eee;
          border-radius: 50%;
          margin-right: 10px;
          overflow: hidden;
          .avatar {
            display: block;
            width: 100%;
          }
        }
        .user-name {
          font-size: 14px;
          color: #000;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .tags {
        padding: 10px;
        height: 50px;
        display: flex;
        align-items: center;
        overflow: hidden;
        .tag {
          color: #fff;
          border-radius: 3px;
          font-size: 12px;
          margin-right: 4px;
          height: 25px;
          display: flex;
          align-items: center;
          padding: 0 5px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
